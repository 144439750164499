import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import TwoColumns from '../components/Studies/TwoColumns'

// Functional component
const CaseStudies = () => {
    return (
        <Layout
            titulo="Nuestros proyectos"
            descripcion="Algunos de los proyectos que nos representan como compañía tecnológica"
            slug="case-studies"
            imagen="https://res.cloudinary.com/dev-empty/image/upload/v1630907493/project_start1_fcd74cc421.png"
        >

            <Navbar />

            <PageBanner
                pageTitle="Algunos de nuestros proyectos" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Casos de estudio" 
            />

            <TwoColumns />

            <Footer />

        </Layout>
    );
}

export default CaseStudies